.banner-mobile{
    display: none;
}
.banner{
    width: 100%;
    object-fit: contain;
}
.encuesta-container {
    margin: 72px 0;
    width: 500px;
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;

    .star {
        width: 60px;
    }

    h3 {
        margin: 14px 0;
        color: #00354F;
        font-weight: 700;
        font-size: 24px;
    }

    .tagline {
        margin-top: 34px;
        font-family: 'Raleway_Normal';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-align: center;

        color: #00354F;
    }

    .pregunta {
        font-family: 'Raleway_Normal';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        color: #4CD6B0;
    }

    .answers-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 43px 0;
        flex-wrap: wrap;

        .answer {
            font-family: 'Raleway_Normal';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #00354F;
            width: 48%;
            text-align: center;
            padding: 23px 0;
            border: solid 2px #00354F;
            border-radius: 8px;
            cursor: pointer;
        }

        .answer:nth-of-type(2)~.answer {
            margin-top: 13px;
        }

        .answer.active {
            color: #4CD6B0;
            background-color: #00354F;
        }

        .img-answer {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #00354F;
            cursor: pointer;

            img {
                width: 60px;
            }
        }
    }

    .submit {
        width: 180px;
        border-radius: 5px;
        background-color: #4CD6B0;
        color: #fff;
    }
}

@media (max-width: 700px) {
    .encuesta-container {
        width: 100vw !important;
        padding: 0 10px;
        .answers-container {
            flex-direction: column;
            align-items: center;
        }
        .answers-container.imgs {
            flex-direction: row;
        }
        .answer {
            width: 90% !important;
        }

        .answer:nth-of-type(1)~.answer {
            margin-top: 11px !important;
        }
    }
    .banner-mobile{
        display: block;
        width: 100%;
        object-fit: contain;
    }
    .banner{
        display: none;
    }
}