.navbar-green {
  .container {
    padding-right: 0 !important;

    padding-left: 0 !important;
  }
  background: var(--green-color);
  padding: 1rem 1.5rem;
  .nav-green-items {
    justify-content: space-between;
    // display: none;

    .regresar {
      display: flex;
      align-items: center;
    }

    img {
      width: 2rem;
      transform: rotate(90deg);
      margin: 0.5rem;
    }
    a {
      margin-right: 2rem;
      font-family: Open_Bold;
      color: white;
      font-size: 1.5rem;
    }
    a:last-child {
      margin: 0;
    }
    .handle-navs {
      display: none;
    }
  }
}

.navbar-white {
  .nav-container {
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    max-height: 6rem;
  }
  .logos {
    display: flex;
    justify-content: space-between;
    flex: 1;
    .logo-bayer {
      max-width: 8rem;
      display: none;
    }
    .logo-latimosJuntos {
      width: 100px;
      object-fit: contain;
    }
  }
  .nav-actions {
    display: none;
    align-items: center;
    justify-content: flex-end;

    a {
      padding: 0.5rem;
      font-family: Open_Bold;
      color: var(--gray-hard-color);
      border-left: 0.1rem solid var(--green-color);
      border-right: 0.1rem solid var(--green-color);
      text-transform: uppercase;
    }
    a:last-child {
      border-left: 1rem;
    }
  }
  .nav-hamburguer {
    width: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    .hamb-item {
      border: 2px solid var(--green-color);
      background: var(--green-color);
      border-radius: 1rem;
      width: 90%;
    }
    .hamb-item:nth-child(2) {
      margin-left: 10px;
    }
    .hamb-item:nth-child(3) {
      width: 85%;
    }
  }
  .overlay {
    position: absolute;
    border: 1px solid blue;
    z-index: 100;
  }
}

.hasDoctor {
  display: none;
}

.nav-big-img {
  img {
    object-fit: contain;
    max-width: 100%;
  }
  .stepper {
    display: none;
    p {
      position: absolute;
      top: 1rem;
      font-family: Raleway_Normal;
      color: white;
    }
    span {
      font-family: Raleway_Bold;
    }
  }
}

.nav-mobile-container {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;

  // border: 1px solid red;
  height: 100%;

  transform: translateX(100%);
  transition: transform 0.3s ease-out;

  .navbar-green {
    width: 100%;
  }
  .mobile-header {
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.5rem;

    .close {
      opacity: 1 !important;
    }
  }

  .mobile-body {
    overflow: scroll;

    overflow: scroll;
    display: flex;
    flex-direction: column;
    height: 100%;

    // border: 1px solid red;
    .mobile-items {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      // width: 100%;
      flex: 1;
      // overflow: auto;

      .mobile-item {
        // border: 1px solid red;
        width: 100%;
        padding: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        p {
          text-transform: uppercase;
          font-family: Open_Bold;
        }
      }
      .green {
        color: white;
        background: var(--green-color);
      }
      .green:nth-child(even) {
        background: #46c5a2;
      }
      .white {
        color: var(--gray-hard-color);
      }
      .white:nth-child(odd) {
        background: #efefef;
      }
    }
  }
}

@media (min-width: 400px) {
  .nav-mobile-container {
    width: 70% !important;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .nav-container {
    max-height: 100% !important;
  }

  .stepper {
    p {
      top: 3rem !important;
    }
  }

  .nav-mobile-container {
    width: 50% !important;
  }

  .logos {
    .logo-latimosJuntos {
      width: 120px !important;
    }
  }
}

// Small devices (landscape phones, 576px and up)

@media (min-width: 768px) {
  .nav-green-items {
    display: flex !important;
  }
  .logo-bayer {
    max-width: 25% !important;
  }

  .logos {
    .logo-bayer {
      max-width: 10rem !important;
      display: flex !important;
    }

    .logo-latimosJuntos {
      width: 125px !important;
      margin: 20px 0;
    }
  }

  .nav-actions {
    display: flex !important;
    a {
      padding: 1rem 2rem !important;
    }
    a:last-child {
      border-left: 0 !important;
    }
  }

  .nav-hamburguer {
    display: none !important;
  }

  .nav-mobile-container,
  .overlay {
    display: none !important;
  }

  .stepper {
    display: flex !important;
  }

  .regresar {
    display: none !important;
  }
  .handle-navs {
    display: flex !important;
    margin-left: auto;
  }

  .hasDoctor {
    display: block !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) {
//   .logos {
//     .logo-latimosJuntos {
//       width: 150px !important;
//       margin: 20px 0;
//     }
//   }
// }
