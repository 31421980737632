.doctor-prev {
  display: flex;
  flex: 1;

  .doctor-item {
    flex: 1;
    padding: 2rem 1rem;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 12rem;
      height: 12rem;
      border-radius: 50%;
      border: 5px solid #4cd6b0;
      object-fit: cover;
    }
  }
  .name {
    flex: 2;
    display: flex;
    align-items: center;
    text-align: center;
    h2 {
      color: #4f4f4f;
      font-size: 2rem;
      text-transform: capitalize;
    }
  }
  .direccion {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 2rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .prev-control {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .react-switch {
        margin-top: 0.5rem;
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }
}
