.consultorios-container.fade-in-slow {
  // border: 1px solid green;
  // max-height: 100rem;
  // height: 100%;
  max-width: 100%;
  width: 100%;
  overflow: auto;

  z-index: 100;
  overflow-x: hidden;
  align-self: flex-start;

  .consultorios-filtros {
    position: sticky;
    top: 0;
    z-index: 90;
    // border: 1px solid green;
    // display: flex;
    display: none;
    .filtro {
      background: var(--hardest-blue-color);
      width: 12%;
      // border: 1px solid green;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      p {
        font-family: Raleway_Bold;
        color: white;
        font-size: 1.3rem;
      }
      select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        display: block;
        // border: 1px solid green;
        background: url("../../Assets/white-chev.png") var(--hardest-blue-color)
          no-repeat 85% center !important;
        background-size: 13px 9px !important;

        font-family: Raleway_Bold;
        font-size: 1.4rem;
        width: 95%;
        height: 85%;

        color: white;

        border-radius: 0;

        padding-right: 4.5rem;
      }

      #Genero {
        padding-right: 3rem;
      }
    }
    .especialidades {
      width: 25%;
    }

    .genero {
      width: 13%;
    }

    .experiencia,
    .colonia,
    .municipio {
      width: 14%;
    }

    .nombre {
      width: 10%;
    }
  }

  .consultorios-body {
    display: none;
    .consultorio {
      background: var(--gray-softest-color);
      display: flex;

      .consultorio-item {
        width: 12%;
        // border: 1px solid green;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .especialidades {
        width: 25%;
      }

      .genero {
        width: 10%;
        button {
          align-self: flex-start;
          width: 100%;
          margin: auto;
        }
      }
      .experiencia,
      .colonia,
      .municipio {
        width: 14%;
      }

      p {
        color: var(--gray-hard-color);
        font-family: Raleway_Normal;
        margin: 0.3rem 0;
        text-align: center;
      }
    }
    .consultorio:nth-child(odd) {
      background: var(--gray-soft1-color);
    }
  }

  .side-drawer {
    display: flex;
    align-items: center;
    background: white;
    position: sticky;
    top: 0;
    z-index: 100;
    p {
      font-family: Open_Normal;
      color: var(--gray-hard-color);
      margin-left: 1rem;
    }

    .side-drawer-hamb {
      width: 4rem;
      height: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      margin-bottom: 0.5rem;

      .hamb-item {
        border: 2px solid var(--marin-blue-color);
        background: var(--marin-blue-color);
        border-radius: 1rem;
        width: 90%;
      }
      .hamb-item:nth-child(2) {
        margin-left: 10px;
      }
      .hamb-item:nth-child(3) {
        width: 85%;
      }
    }
  }
}

.open {
  transform: translateX(0) !important;
}

.side-drawer-container {
  position: fixed;
  top: 0;
  left: 0;

  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  z-index: 1000;

  height: 100%;
  width: 100%;
  overflow: auto;
  background: white;

  display: flex;
  align-items: center;
  flex-direction: column;

  .navbar-green {
    width: 100%;
  }

  .mobile-header {
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.5rem;

    .logo-latimosJuntos {
      width: 10rem;
      margin-top: 0 !important;
    }
    .close {
      margin-top: 0 !important;
      opacity: 1 !important;
    }
  }

  .side-drawer-filtros {
    overflow: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1.5rem;
    background: rgba(239, 239, 239, 0.4);
    flex: 1;
    p {
      align-self: flex-start;
      font-family: Open_Bold;
      color: var(--gray-hard-color);
      font-size: 1.5rem;
    }

    h1 {
      margin: 0 !important;
    }
    .side-drawer-filtro {
      margin: 0.25rem 0;
      p {
        font-family: Open_Normal;
        margin: 0;
      }

      width: 100%;
      select {
        width: 100%;
        max-width: 100%;
        margin: 0.5rem 0 !important;
        border: 1px solid var(--green-color);

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;

        display: block;

        background: url("../../Assets/green-arrow.svg") white no-repeat 95%
          center;
        padding-right: 5rem;

        text-overflow: ellipsis !important;
        overflow: hidden !important;
      }
    }
  }
}

.overlay {
  left: 0;
  height: 100% !important;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .consultorios-container {
    // border: 1px solid green;
    max-height: 100%;
  }

  .consultorios-filtros {
    display: flex !important;
  }

  .consultorios-body {
    display: block !important;
  }

  .side-drawer,
  side-drawer-container,
  .overlay,
  .consultorios-mobile {
    display: none !important;
  }

  .hasMunicipio {
    .filtro,
    .consultorio-item {
      width: 15% !important;
    }

    .especialidades {
      width: 25% !important;
    }
  }
}

@media (min-width: 400px) {
  .side-drawer-container {
    width: 70% !important;
  }
}

@media (min-width: 576px) {
  .side-drawer-container {
    width: 50% !important;
  }
}
