.consultorios-mobile {
  .consultorio {
    background: var(--hard-blue-color);

    color: white;

    margin: 0.25rem 0;
    .accordion__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .name-doctor {
      padding: 1rem 0.5rem;
    }

    .doctor-body {
      width: 100%;

      .body-item {
        padding: 1rem 0.5rem;
        background: var(--gray-soft1-color);
        p {
          color: var(--gray-hard-color);
        }
      }

      .body-item:nth-child(even) {
        background: var(--gray-softest-color);
      }
      .green-btn {
        margin: 0;
        width: 100%;
        border-radius: 0;
      }

      .especialidades {
        p {
          border-bottom: 1px dotted gray;
        }
        p:last-child {
          border-bottom: 0;
        }
      }
    }

    .admin {
      .opacity {
        opacity: 0.1;
      }
      .body-item {
        border: 1px solid #bdbdbd;
        padding: 1rem;
      }
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          border-radius: 50%;
          border: 5px solid #4cd6b0;
          width: 20rem;
          height: 20rem;
          object-fit: cover;
        }
      }

      .btn {
        padding: 0;
        display: block;
      }
    }
  }
  .consultorio:nth-child(odd) {
    background: var(--hardest-blue-color) !important;
  }

  .accordion__button:after {
    display: inline-block;
    content: "";
    height: 3rem;
    width: 3rem;
    margin-right: 12px;

    background: url("../../Assets/green-arrow-right.svg") no-repeat center !important;
  }

  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    transform: rotate(90deg);
  }
}
