.form_login {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  flex: 1;
  .doc_img {
    flex: 1;
    position: relative;
    .bg_green {
      position: absolute;
      z-index: 10;
      background: linear-gradient(
        0deg,
        rgba(76, 214, 176, 0.3) 0%,
        rgba(76, 214, 176, 0) 100%
      );
      width: 100%;
      height: 100%;
    }

    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: #efefef;

    padding: 2rem 1rem;
    .line {
      background: #00354f;
      margin-bottom: 2rem;

      width: 65.71px;
      height: 5.48px;
    }
    h1 {
      color: #00354f;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 5rem;
    }
    input {
      width: 100%;
      max-width: 52rem;
      padding: 1rem !important;
      margin-top: 2rem;
      &:last-of-type {
        margin-top: 2rem;
      }
    }
    span {
      font-size: 1rem;
    }
    .green-btn {
      font-size: 2rem;
      margin-top: 5rem;
    }
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .form_login {
    display: flex;
    flex-direction: row;
  }

  .doc_img {
    flex: 1;
    width: 50%;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media (min-width: 1200px) {
}
