.doctor {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  .goBack {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    display: none;
    a {
      color: var(--gray-hard-color);
    }
    img {
      margin-right: 0.5rem;
    }
  }

  .main-container {
    max-width: 100%;
    .doctor-container {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      .doctor-imagen {
        margin: 2rem auto;
        justify-content: flex-end;
        .imagen {
          padding: 1rem;
          background: var(--green-color);
          border-radius: 50%;
          img {
            object-fit: cover;
            height: 20rem;
            width: 20rem;
            border-radius: 50%;
          }
        }
      }

      .informacion {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // border: 1px solid red;

        width: 100%;
        &-header {
          // border: 1px solid red;
          margin: 0 auto;
          h1 {
            font-size: 3rem;
            color: var(--gray-hard-color);
            margin: 0;
          }
          h1:nth-child(2) {
            color: var(--green-color);
          }
          .experiencia {
            display: flex;
            align-items: center;
            flex-direction: column;

            h3 {
              font-family: Raleway_Bold;
              color: var(--gray-hard-color);
              margin: 0;
              font-size: 2rem;
            }
            span {
              font-family: Raleway_Normal;
              font-size: 2rem;
              margin-left: 0.5rem;
              color: var(--gray-hard-color);
            }
          }
        }
        .consultorio-slides {
          flex: 1;
          max-width: 100%;
          .carousel-root {
            .carousel-slider {
              .slide {
                background: inherit;
                .consultorio {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  .imagen {
                    border-radius: 1rem;
                    width: auto;
                    height: 30rem;
                    display: none;
                    img {
                      object-fit: fill;
                      max-height: 100%;
                      max-width: 100%;
                      border-radius: 1rem;
                      filter: grayscale(100%);
                    }
                  }
                  .horarios {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 2rem 0;

                    width: 100%;

                    justify-content: flex-start;
                    align-content: flex-start;
                    align-items: flex-start;

                    .horario {
                      flex: 0 0 48%;
                      background: var(--gray-soft1-color);
                      margin: 0.2rem;
                      border-radius: 1rem;
                      color: #afafaf;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: space-between;
                      padding: 0.5rem;

                      height: 14rem;
                      .dia {
                        font-size: 1.5rem;
                        font-family: Raleway_BoldItalic;
                      }
                      .hr {
                        font-size: 1.2rem;
                      }
                      img {
                        width: 5rem;

                        margin: 1rem;
                      }
                    }
                    .isToday {
                      background: var(--green-color);
                      color: white;
                    }
                  }
                  .direccion {
                    padding: 0.5rem;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    flex-direction: column;
                    width: 100%;
                    img {
                      margin-right: 1rem;
                      width: 6rem;
                    }
                    p {
                      text-align: left;
                      font-size: 1.8rem;
                      text-align: center;
                      color: var(--gray-hard-color);
                    }
                  }
                  .contacto {
                    margin: 2rem 0;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    .contacto-item {
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      margin: 0.5rem;
                      padding: 0.5rem;
                      img {
                        width: 6rem;
                        margin-right: 1rem;
                      }
                      a {
                        text-align: left;
                        font-family: Raleway_Normal;
                        color: var(--gray-hard-color);
                        font-size: 1.8rem;
                        text-align: center;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .doctor-botones {
      margin-bottom: 2rem;
    }
  }
}

.control-next:before {
  content: url("../Assets/green-arrow-right.svg") !important;
  border-top: 0 solid transparent !important;
  border-bottom: 0 solid !important;
  border-left: 0px solid #fff !important;
  display: none !important;
}

.carousel .control-prev.control-arrow:before {
  content: url("../Assets/green-arrow-right.svg") !important;
  transform: rotate(180deg);
  border-right: 0px solid #fff !important;
  display: none !important;
}

.carousel .carousel-status {
  top: 51% !important;
  right: 5px !important;
  font-size: 1.5rem !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0) !important;
  color: var(--gray-soft1-color) !important;
  font-family: Open_Bold !important;
  display: none !important;
}

.carousel .control-dots {
  bottom: 3px !important;
}

.carousel .control-dots .dot {
  opacity: 1 !important;

  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0) !important;
  background: #c4c4c4 !important;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--green-color) !important;
}

@media (min-width: 400px) {
  .consultorio {
    .horarios {
      .horario {
        flex: 0 0 32% !important;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .consultorio {
    .imagen {
      display: block !important;
    }

    .horarios {
      .horario {
        flex: 0 0 24% !important;
        // margin: 0.5rem !important;
      }
    }

    .contacto {
      flex-direction: row !important;
      .contacto-item {
        flex-direction: row !important;
      }
    }

    .direccion {
      flex-direction: row !important;
      p {
        text-align: left !important;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .slide .consultorio {
    // background: pink;
    width: 85% !important;
    margin: auto;
  }

  .carousel .control-dots .dot,
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    display: none !important;
  }

  .control-next:before,
  .carousel .control-prev.control-arrow:before,
  .carousel .carousel-status {
    display: block !important;
  }

  .goBack {
    display: flex !important;
  }

  .horarios {
    .horario {
      flex: 0 0 23% !important;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .doctor-container {
    flex-direction: row !important;
  }

  .informacion {
    width: 50% !important;
    margin: 2rem;
    .imagen {
      margin: 2rem 0;
    }

    &-header {
      width: 85%;
      align-self: center;
      h1 {
        text-align: left !important;
        margin: 0 !important;
      }
      .experiencia {
        width: 100%;
        align-items: center !important;

        flex-direction: row !important;
      }
    }
  }

  .doctor-imagen {
    align-self: flex-start !important;
  }

  .doctor-imagen {
    img {
      height: 30rem !important;
      width: 30rem !important;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}
