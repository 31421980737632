.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // border: 1px solid red;
  height: 100%;
  flex: 1;
  .header {
    margin-top: 1rem;

    .home-titles {
      h1 {
        margin-top: 1.5rem;
      }
    }
  }

  h3 {
    text-align: center;
    color: var(--gray-hard-color);

    // border: 1px solid red;
  }
  &-form {
    margin: 2rem 0;
    // border: 1px solid red;
    // margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .select-group {
      // border: 1px solid red;
      display: flex;
      width: 100%;
      margin: auto;
      margin-bottom: 1rem;
      .home-form-select {
        flex: 1;
        select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          -ms-appearance: none;
          display: block;
          margin: 0.5rem auto;
          background: url("../Assets/Arrow-select.svg") var(--gray-soft2-color)
            no-repeat 95% center;

          padding-right: 3rem;
          width: 90%;

          text-overflow: ellipsis !important;
          overflow: hidden !important;
        }
      }
    }

    .home-button {
      margin: auto;
      button {
        margin: 0 !important;
      }
    }
    .spin {
      height: 10rem;
    }
  }
  &-body-research {
    // border: 1px solid red;

    width: 100%;

    min-height: 20rem;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    img {
      object-fit: contain;
      max-width: 100%;
      margin: 5rem 0;
    }

    .spener {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner-grow {
        position: inherit !important;
      }
    }
  }
}

@media (min-width: 576px) {
  .home {
    &-form {
      // height: 12rem;
      // margin: 2rem 0;

      .select-group {
        width: 90%;

        select {
          padding-right: 4rem !important;
        }
      }
    }
  }
  .home-body-research {
  }
}

@media (min-width: 1200px) {
  .home {
    .select-group {
      width: 70%;
    }
  }
}

@media (min-width: 768px) {
  .home {
    &-body-research {
    }
    .header {
      margin-top: 0rem;
    }
  }

  .nav-big-img {
    position: relative !important;
  }
}
