*,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  box-sizing: border-box;
  line-height: 1.6;
  font-family: Open_Normal;
}

@font-face {
  font-family: Raleway_Normal;
  src: url("./Assets/Fonts/Raleway/Raleway-VariableFont_wght.ttf");
}

@font-face {
  font-family: Raleway_Bold;
  src: url("./Assets/Fonts/Raleway/static/Raleway-Bold.ttf");
}

@font-face {
  font-family: Raleway_BoldItalic;
  src: url("./Assets/Fonts/Raleway/static/Raleway-BoldItalic.ttf");
}

@font-face {
  font-family: Open_Normal;
  src: url("./Assets/Fonts/Open_Sans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: Open_Bold;
  src: url("./Assets/Fonts/Open_Sans/OpenSans-SemiBold.ttf");
}

:root {
  --green-color: #4cd6b0;
  --gray-hard-color: #7a7a7a;
  --gray-soft1-color: #e5e5e5;
  --gray-soft2-color: #f4f4f4;
  --gray-softest-color: #f8f8f8;
  --white-color: white;
  --marin-blue-color: #04537a;
  --hard-blue-color: #00354f;
  --hardest-blue-color: #002230;
}

*:focus {
  outline: none !important;
}

h1 {
  font-size: 2.8rem;
  font-family: Raleway_Bold;
  color: var(--green-color);
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: 2.3rem;
  font-family: Raleway_Bold;
}

h3 {
  font-size: 1.5rem;
  font-family: Raleway_Normal;
}

p,
span,
button,
label,
input,
select {
  font-size: 1.3rem;
  font-family: Open_Normal;
  margin: 0;
}

a,
small {
  font-size: 1rem;
  font-family: Open_Normal;
}

select,
input {
  padding: 0.6rem !important;
  border-radius: 0.5rem !important;
  border: 0;
  color: var(--gray-hard-color) !important;
  background: var(--gray-soft2-color);

  font-family: Open_Bold;
}

select {
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}

*:disabled {
  opacity: 0.6;
  cursor: default;
}

button {
  align-self: center;
  padding: 0.6rem 2rem;

  border-radius: 0.5rem;
  border: 0;
  cursor: pointer;
  text-decoration: none !important;
}

button:active {
  opacity: 0.6 !important;
}

@media (min-width: 576px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2rem;
  }
  p,
  span,
  button,
  label,
  input,
  select {
    font-size: 1.5rem;
  }

  select {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }

  a,
  small {
    font-size: 1.4rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// * {
//   border: 1px solid red;
// }
