.admin_home {
  flex: 1;
  padding-bottom: 2rem;
  background: #e5e5e5;
  padding-top: 3rem;
  position: relative;

  .controlls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
    div {
      button {
        margin-right: 1rem;
        &:last-child {
          margin-top: 1rem;
        }
      }
      .disabled {
        opacity: 1;
      }
    }
  }

  .header {
    margin-bottom: 3rem;

    input {
      max-width: 100%;
      width: 35rem;
      margin-bottom: 1rem;
      margin-right: 2rem;
    }

    div {
      .white-btn {
        margin-left: 1rem;
      }
    }
  }
  .lista_medicos {
    display: none;
    .doctor-prev {
      background: #e5e5e5;
      border: 1px solid #bdbdbd;
      &:nth-child(odd) {
        background: #ffffff;
      }
    }
    .opacity {
      opacity: 0.5;
    }
  }

  .not_found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1.blue_text {
      color: #00354f;
    }
    img {
      margin: 3rem 0;
      max-width: 25rem;
      width: 100%;
    }
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .admin_home {
    .header {
      display: flex;
      justify-content: space-between;

      input {
        margin: 0;
        background: #ffffff;
      }
    }

    .controlls {
      div {
        button {
          &:last-child {
            margin-top: 0rem;
          }
        }
      }
    }

    .lista_medicos {
      display: flex;
      flex-direction: column;
    }
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
