.footer {
  display: flex;
  flex-direction: column;

  .top {
    background-color: var(--hard-blue-color);
    color: white;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
    // border: 1px solid red;

    &-links-list {
      display: none;
      text-align: center;
      margin: auto;
      // border: 1px solid red;
      a {
        color: white;
        text-decoration: underline;
        font-size: 1.4rem;
      }
    }
    &-body {
      // border: 1px solid red;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .items {
        // border: 1px solid red;
        p {
          text-align: center;
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
        }
      }
      img {
        object-fit: contain;
        max-width: 100%;
        margin: 1rem 0;
      }
    }

    .logo-latimos {
      // border: 1px solid red;
      display: none;
    }

    .bayer-logos {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // border: 1px solid red;
      margin: 0.5rem 0;
      img {
        width: 40%;
        max-width: 100%;
        // border: 1px solid red;
      }
      .bayer-Sponsored {
        // border: 1px solid red;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 30%;
          margin-left: 0.5rem;
        }
      }
    }
  }
  .bottom {
    background-color: var(--marin-blue-color);
  }
  .social-media {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      padding: 1rem;
      width: 7rem;
    }
  }
}

@media (min-width: 576px) {
  .footer {
    flex-direction: column;
  }

  .bayer-logos {
    justify-content: space-evenly !important;
    .bayer-Sponsored {
      width: 40% !important;
      img {
        width: 40% !important;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .footer {
    .top {
      flex-wrap: nowrap;
      flex-direction: row;
      .container {
        display: flex;
      }
      &-links-list {
        width: 20%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
      }
      &-body {
        width: 60%;
        margin: 0;
      }
      .bayer-logos {
        width: 20%;
      }
      .bayer-Sponsored {
        width: auto !important;
        flex-direction: column !important;
        img {
          margin: 1rem 0;
        }
      }
      .logo-latimos {
        display: flex !important;
        align-items: center;
      }
      .logo-latimos-responsive {
        display: none !important;
      }
    }
  }
}

@media (min-width: 998px) {
  .footer {
    .top {
      .bayer-Sponsored {
        flex-direction: row !important;
      }
    }
  }
}
