.delete-overlay {
  background: rgba(1, 1, 1, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden !important;
  z-index: 10000;

  .delete-container {
    background: #fbfbfb;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 90%;
    max-width: 500px;
    height: 15rem;

    h2 {
      color: #4cd6b0;
      text-align: center;
    }
    .delete-spinner {
      .spinner-grow {
        bottom: -30px !important;
      }
    }

    .autoDel {
      margin-top: 10px;
    }

    .delete-btns {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      flex: 1;
    }
  }
}

.msn {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
}
