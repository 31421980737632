.fade-in-slow {
  animation: fadeinSlow 0.8s forwards ease-in;
}

@keyframes fadeinSlow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-fast {
  animation: fadeinFast 0.4s forwards ease-in;
}

@keyframes fadeinFast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.green-btn {
  background: var(--green-color);
  color: white;
}

.red-btn {
  background: red;
  color: white;
}

.white-btn {
  color: var(--green-color);
  background: #ffffff;
  font-weight: bold;
}

button:hover{
  opacity: .5;
}

.green-btn:hover {
  text-decoration: underline;
}
.green-btn:active {
  opacity: 0.1;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: initial !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.overlay {
  width: 100vw;
  height: 120vh;
  background: rgba(1, 1, 1, $alpha: 0.6);
  position: absolute;
  top: 0;
  z-index: 900;
}

.latimos-container {
  position: relative !important;
  top: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.latimos {
  flex: 1 0 100%;
  height: auto;
}

.spin {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  background: white;
  z-index: 90;
}

@media (min-width: 768px) {
}
